import { ChakraProvider } from '@chakra-ui/react'
import {
  defaultContext,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { AIComponentsProvider } from 'ai-components'
import type { AppProps } from 'next/app'
import NextNprogress from 'nextjs-progressbar'
import { useState } from 'react'
import ErrorBoundary from '../components/ErrorHandler'
import { customTheme } from '../styles/theme'
import Head from 'next/head'

// changes
function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <ChakraProvider theme={customTheme}>
      <QueryClientProvider context={defaultContext} client={queryClient}>
        <AIComponentsProvider queryClientCtx={defaultContext}>
          <ErrorBoundary>
            <Head>
              <meta charSet='utf-8' />
              <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
              <meta
                name='viewport'
                content='width=device-fwidth,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
              />
              <meta name='description' content='Autoinspector' />

              <link rel='icon' href='/assets/icon-192x192.png?v=2' />
            </Head>
            <NextNprogress
              options={{
                showSpinner: false,
              }}
            />
            <style jsx global>
              {`
                .space-x-0
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0px * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0px * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }

                .space-x-1
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0.25rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0.25rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-2
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-3
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0.75rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0.75rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-4
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    1rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    1rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    1.25rem * var(--tw-space-x-reverse)
                  ) !important ;
                  margin-left: calc(
                    1.25rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-6
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    1.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    1.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-7
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    1.75rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    1.75rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-8
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    2rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    2rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-9
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    2.25rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    2.25rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-10
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    2.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    2.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-11
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    2.75rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    2.75rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-12
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    3rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    3rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-14
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    3.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    3.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-16
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    4rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    4rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-20
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-24
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    6rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    6rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-28
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    7rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    7rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-32
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    8rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    8rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-36
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    9rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    9rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-40
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    10rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    10rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-44
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    11rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    11rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-48
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    12rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    12rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-52
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    13rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    13rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-56
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    14rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    14rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-60
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    15rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    15rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-64
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    16rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    16rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-72
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    18rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    18rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-80
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    20rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    20rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-96
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    24rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    24rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-px
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    1px * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    1px * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-0\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0.125rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0.125rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-1\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0.375rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0.375rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-2\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0.625rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0.625rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-x-3\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0.875rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0.875rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-0
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    0px * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    0px * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-1
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -0.25rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -0.25rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-2
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -0.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -0.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-3
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -0.75rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -0.75rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-4
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -1rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -1rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -1.25rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -1.25rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-6
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -1.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -1.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-7
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -1.75rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -1.75rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-8
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -2rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -2rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-9
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -2.25rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -2.25rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-10
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -2.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -2.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-11
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -2.75rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -2.75rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-12
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -3rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -3rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-14
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -3.5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -3.5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-16
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -4rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -4rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-20
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -5rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -5rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-24
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -6rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -6rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-28
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -7rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -7rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-32
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -8rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -8rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-36
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -9rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -9rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-40
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -10rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -10rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-44
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -11rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -11rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-48
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -12rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -12rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-52
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -13rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -13rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-56
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -14rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -14rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-60
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -15rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -15rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-64
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -16rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -16rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-72
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -18rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -18rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-80
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -20rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -20rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-96
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -24rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -24rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-px
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -1px * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -1px * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-0\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -0.125rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -0.125rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-1\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -0.375rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -0.375rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-2\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -0.625rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -0.625rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .-space-x-3\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-x-reverse: 0 !important;
                  margin-right: calc(
                    -0.875rem * var(--tw-space-x-reverse)
                  ) !important;
                  margin-left: calc(
                    -0.875rem * calc(1 - var(--tw-space-x-reverse))
                  ) !important;
                }
                .space-y-0
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0px * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0px * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-1
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0.25rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0.25rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-2
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-3
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0.75rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0.75rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-4
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important ;
                  margin-top: calc(
                    1rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    1rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    1.25rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    1.25rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-6
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    1.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    1.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-7
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    1.75rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    1.75rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-8
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    2rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    2rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-9
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    2.25rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    2.25rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-10
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    2.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    2.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-11
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    2.75rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    2.75rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-12
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    3rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    3rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-14
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    3.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    3.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-16
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    4rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    4rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-20
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-24
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    6rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    6rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-28
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    7rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    7rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-32
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    8rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    8rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-36
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    9rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    9rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-40
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    10rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    10rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-44
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    11rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    11rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-48
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    12rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    12rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-52
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    13rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    13rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-56
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    14rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    14rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-60
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    15rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    15rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-64
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    16rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    16rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-72
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    18rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    18rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-80
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    20rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    20rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-96
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    24rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    24rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-px
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    1px * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    1px * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-0\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0.125rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0.125rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-1\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0.375rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0.375rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-2\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0.625rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0.625rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .space-y-3\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0.875rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0.875rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-0
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    0px * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    0px * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-1
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -0.25rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -0.25rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-2
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -0.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -0.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-3
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -0.75rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -0.75rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-4
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -1rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -1rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -1.25rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -1.25rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-6
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -1.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -1.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-7
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -1.75rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -1.75rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-8
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -2rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -2rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-9
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -2.25rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -2.25rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-10
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -2.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -2.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-11
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -2.75rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -2.75rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-12
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -3rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -3rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-14
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -3.5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -3.5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-16
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -4rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -4rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-20
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -5rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -5rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-24
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -6rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -6rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-28
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -7rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -7rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-32
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -8rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -8rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-36
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -9rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -9rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-40
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -10rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -10rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-44
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -11rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -11rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-48
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -12rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -12rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-52
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -13rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -13rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-56
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -14rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -14rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-60
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -15rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -15rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-64
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -16rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -16rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-72
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -18rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -18rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-80
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -20rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -20rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-96
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -24rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -24rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-px
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -1px * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -1px * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-0\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -0.125rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -0.125rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-1\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -0.375rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -0.375rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-2\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -0.625rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -0.625rem * var(--tw-space-y-reverse)
                  ) !important;
                }
                .-space-y-3\.5
                  > :not([hidden]):not(style)
                  ~ :not([hidden]):not(style) {
                  --tw-space-y-reverse: 0 !important;
                  margin-top: calc(
                    -0.875rem * calc(1 - var(--tw-space-y-reverse))
                  ) !important;
                  margin-bottom: calc(
                    -0.875rem * var(--tw-space-y-reverse)
                  ) !important;
                }
              `}
            </style>
            <Component isMobile={false} {...pageProps} />
          </ErrorBoundary>
        </AIComponentsProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default MyApp
